import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';

import { Button, Typography } from '@mui/material';
import AllContacts from './AllContacts/AllContacts';
import AddCarouselDetails from './AddCarouselDetails/AddCarouselDetails';
import Users from './Users/Users';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [activeComponent, setActiveComponent] = useState('');
  const [showWelcome, setShowWelcome] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
    setShowWelcome(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleComponentChange = (component) => {
    setActiveComponent(component);
    setShowWelcome(false);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'users':
        return <Users/>;
      case 'all-contacts':
        return <AllContacts />;
      case 'add-carousel-details':
        return <AddCarouselDetails />;
      // case 'all-carousel':
      //   return <CarouselTable />;
      
      
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: '#1A3668' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ background: "#1A3668" }}>
          <IconButton style={{ background: "white" }} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List style={{ backgroundColor: '#1A3668', height: '100vh' }}>
          <ListItem disablePadding>
            <Button style={{ width: "100%", background: "#1A3668", marginTop: "20px" }} variant="contained" href="">
              <Link to="/" style={{ textDecoration: 'none', color: "white" }}>
                Home
              </Link>
            </Button>
          </ListItem>

          <ListItem disablePadding>
            {/* <Button
              style={{ width: '100%', background: '#1A3668', marginTop: '20px' }}
              variant="contained"
              onClick={() => handleComponentChange('all-project')}
            >
              All Project
            </Button> */}
          </ListItem>
          <ListItem disablePadding>
            <Button
              style={{ width: '100%', background: '#1A3668', marginTop: '20px' }}
              variant="contained"
              onClick={() => handleComponentChange('users')}
            >
              Users
            </Button>
          </ListItem>
          <ListItem disablePadding>
            <Button
              style={{ width: '100%', background: '#1A3668', marginTop: '20px' }}
              variant="contained"
              onClick={() => handleComponentChange('all-contacts')}
            >
              All Contact List
            </Button>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {showWelcome && (
          <Typography paragraph>
            <h1>Welcome to Dashboard</h1>
          </Typography>
        )}
        <Typography paragraph>
          {renderActiveComponent()}
        </Typography>
      </Box>
    </Box>
  );
}
