import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const FreeConsultation = () => {
  const [consultationName, setConsultationName] = useState('');
  const [consultationEmail, setConsultationEmail] = useState('');
  const [consultationPhone, setConsultationPhone] = useState('');
  const [consultationMessage, setConsultationMessage] = useState('');
  const [consultationDate, setConsultationDate] = useState('');

  const dateInputRef = useRef(null);

  const clearConsultationInput = () => {
    setConsultationName('');
    setConsultationEmail('');
    setConsultationPhone('');
    setConsultationMessage('');
    setConsultationDate('');
  };

  const sendConsultationEmail = (e) => {
    e.preventDefault();
    document.getElementById('consultationSubmitBtn').disabled = true;
    document.getElementById('consultationSubmitBtn').innerHTML = 'Loading...';

    const templateParams = {
      from_name: consultationName,
      from_email: consultationEmail,
      phone_number: consultationPhone,
      message: consultationMessage,
      date: consultationDate,
    };

    const serviceId = 'service_1knfku9';
    const templateId = 'template_l3wspa8';
    const userId = 'iWF8xbb9Wc4LQS11v';

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(
        (response) => {
          document.getElementById('consultationSubmitBtn').disabled = false;
          document.getElementById('consultationSubmitBtn').innerHTML = 'Request Consultation';
          clearConsultationInput();
          Notiflix.Report.success(
            'Success',
            'Your consultation request has been sent successfully!',
            'Okay'
          );
        },
        (error) => {
          document.getElementById('consultationSubmitBtn').disabled = false;
          document.getElementById('consultationSubmitBtn').innerHTML = 'Request Consultation';
          Notiflix.Report.failure(
            'An error occurred',
            'Your consultation request could not be sent. Please try again later.',
            'Okay'
          );
        }
      );
  };

  const handleDateChange = (e) => {
    setConsultationDate(e.target.value);
    if (dateInputRef.current) {
      dateInputRef.current.blur(); // Close the calendar
    }
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="flex justify-center items-center h-screen">
        <div className="w-full container mx-auto bg-white p-8 md:px-12 lg:px-20 rounded-2xl shadow-2xl " data-aos="zoom-in">
          <div className="flex justify-center mb-4 h-32">
            <h1 className="font-bold text-center text-blue-900 uppercase text-4xl">
              Free Consultation
            </h1>
          </div>
          <form onSubmit={sendConsultationEmail}>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
              <div>
                <input
                  name="consultation_name"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Name*"
                  value={consultationName}
                  onChange={(e) => setConsultationName(e.target.value)}
                />
              </div>
              <div>
                <input
                  name="consultation_email"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Email*"
                  value={consultationEmail}
                  onChange={(e) => setConsultationEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  name="consultation_phone"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="Phone*"
                  value={consultationPhone}
                  onChange={(e) => setConsultationPhone(e.target.value)}
                />
              </div>
              <div>
                <textarea
                  name="consultation_message"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  placeholder="Message"
                  value={consultationMessage}
                  onChange={(e) => setConsultationMessage(e.target.value)}
                />
              </div>
              <div>
                <input
                  name="consultation_date"
                  ref={dateInputRef}
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="datetime-local"
                  placeholder="Preferred Date and Time*"
                  value={consultationDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className="my-2 w-full mt-8 flex justify-center">
              <button
                type="submit"
                id="consultationSubmitBtn"
                className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-1/2 focus:outline-none focus:shadow-outline"
              >
                Request Consultation
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default FreeConsultation;
