import React, { useState, useEffect } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, Box
} from '@mui/material';
import Notiflix from 'notiflix';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: 'User' });

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://skyland-backend.vercel.app/api/v1/users', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data.data.reverse());
      } else {
        console.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteDialogOpen(false);
    if (userToDelete) {
      try {
        const response = await fetch(`https://skyland-backend.vercel.app/api/v1/users/${userToDelete._id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setUsers(prevUsers => prevUsers.filter(user => user._id !== userToDelete._id));
          Notiflix.Notify.success('User deleted successfully');
        } else {
          console.error('Failed to delete user');
          Notiflix.Notify.failure('Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        Notiflix.Notify.failure('Error deleting user');
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const handleEdit = (user) => {
    setUserToEdit(user);
    setEditDialogOpen(true);
  };

  const handleUpdateUser = async () => {
    setEditDialogOpen(false);
  
    if (userToEdit) {
      // Prepare the data to be updated
      const { _id, name, email, role } = userToEdit;
  
      // Log the data before sending
      console.log('Updating user with data:', { name, email, role });
  
      try {
        const response = await fetch(`https://skyland-backend.vercel.app/api/v1/users/${_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ name, email, role }),
        });
  
        if (response.ok) {
          const updatedUser = await response.json();
          setUsers(prevUsers => prevUsers.map(user => user._id === updatedUser.data._id ? updatedUser.data : user));
          Notiflix.Notify.success('User updated successfully');
        } else {
          const errorResponse = await response.json();
          console.error('Failed to update user:', errorResponse.message);
          Notiflix.Notify.failure(`Failed to update user: ${errorResponse.message}`);
        }
      } catch (error) {
        console.error('Error updating user:', error);
        Notiflix.Notify.failure('Error updating user');
      }
    }
  };
  
  const handleCreateUser = async () => {
    try {
      const response = await fetch('https://skyland-backend.vercel.app/api/v1/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newUser),
      });

      if (response.ok) {
        const createdUser = await response.json();
        setUsers(prevUsers => [createdUser.data, ...prevUsers]);
        setNewUser({ name: '', email: '', password: '', role: 'User' });
        Notiflix.Notify.success('User created successfully');
      } else {
        console.error('Failed to create user');
        Notiflix.Notify.failure('Failed to create user');
      }
    } catch (error) {
      console.error('Error creating user:', error);
      Notiflix.Notify.failure('Error creating user');
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <TextField
          label="Name"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        />
        <TextField
          label="Email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
        />
        <TextField
          label="Password"
          type="password"
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        />
        <Select
          value={newUser.role}
          onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
        >
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
        </Select>
        <Button variant="contained" color="primary" onClick={handleCreateUser}>
          Create User
        </Button>
      </Box>

      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={userToEdit?.name || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, name: e.target.value })}
            fullWidth
          />
          <TextField
            label="Email"
            value={userToEdit?.email || ''}
            onChange={(e) => setUserToEdit({ ...userToEdit, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Select
            value={userToEdit?.role || 'User'}
            onChange={(e) => setUserToEdit({ ...userToEdit, role: e.target.value })}
            fullWidth
            margin="normal"
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateUser} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{new Date(user.createAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleEdit(user)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(user)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
