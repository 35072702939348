// import React from 'react';
// import { HashLink } from 'react-router-hash-link';

// const NavLinks = () => {
//     return (
//         <>
//             <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about">
//                 About
//             </HashLink>
//             <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
//                 Services
//             </HashLink>
//             {/* <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/#portfolio">
//                 Team
//             </HashLink> */}
//             <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact">
//                 Contact Us
//             </HashLink>
//             <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/consultation#consultation">
//                 Free Consultation
//             </HashLink>
//             <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/skyland-va">
//                 SKYLAND VA
//             </HashLink>
//             <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
//                 Demo our products
//             </HashLink>
//         </>
//     )
// }

// export default NavLinks;


// import React from 'react';
// import { HashLink } from 'react-router-hash-link';

// const NavLinks = ({ onClick }) => {
//     return (
//         <>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 smooth
//                 to="/#about"
//                 onClick={onClick}
//             >
//                 About
//             </HashLink>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 smooth
//                 to="/#services"
//                 onClick={onClick}
//             >
//                 Services
//             </HashLink>
//             {/* Uncomment if you want to use the Team link */}
//             {/* <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/#portfolio"
//                 onClick={onClick}
//             >
//                 Team
//             </HashLink> */}
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/contact#contact"
//                 onClick={onClick}
//             >
//                 Contact Us
//             </HashLink>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/consultation#consultation"
//                 onClick={onClick}
//             >
//                 Free Consultation
//             </HashLink>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/skyland-va"
//                 onClick={onClick}
//             >
//                 SKYLAND VA
//             </HashLink>
//             <HashLink
//                 className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
//                 smooth
//                 to="/get-demo#demo"
//                 onClick={onClick}
//             >
//                 Demo our products
//             </HashLink>
//         </>
//     );
// };

// export default NavLinks;



import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaChevronDown, FaUser } from 'react-icons/fa'; // Import FaUser for the login icon
import Notiflix from 'notiflix';
import axios from 'axios';

const NavLinks = ({ onClick }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        // Check if userRole is present in localStorage
        const role = localStorage.getItem('userRole');
        if (role) {
            setUserRole(role);
        }
    }, []);

    const handleLogout = async () => {
        try {
            await axios.delete('https://skyland-backend.vercel.app/api/v1/auth/logout');
            Notiflix.Notify.success('Logout successful!');
            localStorage.removeItem('userRole');
            setUserRole(null);
            // Redirect to the home page or login page
            window.location.href = '/';
        } catch (err) {
            Notiflix.Notify.failure('Logout failed. Please try again.');
            console.error('Logout error:', err);
        }
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown-wrapper')) {
                closeDropdown();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative dropdown-wrapper flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                smooth
                to="/#about"
                onClick={() => {
                    onClick();
                    closeDropdown();
                }}
            >
                About
            </HashLink>

            <div className="relative inline-block">
                <span
                    className="px-4 font-extrabold text-gray-500 hover:text-blue-900 cursor-pointer"
                    onClick={handleDropdownToggle}
                >
                    Services <FaChevronDown className="inline ml-1" />
                </span>
                {isDropdownOpen && (
                    <div className="absolute left-0 mt-2 w-[300px] bg-white shadow-lg rounded-lg z-10">
                        <div className="grid grid-cols-2 gap-2 p-4">
                            {[
                                'custom-software-development',
                                'web-application-development',
                                'mobile-app-development',
                                'domain-hosting-services',
                                'IT-consultations',
                                'content-creation-services',
                                'cybersecurity-services',
                                'SEO-services',
                                'digital-marketing-services',
                                'accounting-services',
                                'data-entry-services',
                                'crypto-tracing-services',
                                'outreach-services',
                            ].map((service, index) => (
                                <HashLink
                                    key={index}
                                    className="text-gray-700 hover:text-blue-900 flex items-center"
                                    smooth
                                    to={`/#${service}`}
                                    onClick={() => {
                                        closeDropdown();
                                        onClick();
                                    }}
                                >
                                    {service.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                                </HashLink>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                to="/contact#contact"
                onClick={() => {
                    onClick();
                    closeDropdown();
                }}
            >
                Contact Us
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                to="/consultation#consultation"
                onClick={() => {
                    onClick();
                    closeDropdown();
                }}
            >
                Free Consultation
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                to="/skyland-va"
                onClick={() => {
                    onClick();
                    closeDropdown();
                }}
            >
                SkylandWeb VA
            </HashLink>


            {/* Conditionally Render the Dashboard Link if userRole is Admin */}
            {userRole === 'Admin' && (
                <HashLink
                    className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                    to="/dashboard"
                    onClick={() => {
                        onClick();
                        closeDropdown();
                    }}
                >
                    Dashboard
                </HashLink>
            )}
            <HashLink
                className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                smooth
                to="/get-demo#demo"
                onClick={() => {
                    onClick();
                    closeDropdown();
                }}
            >
                Demo our products
            </HashLink>
            {/* Login/Logout Button */}
            {userRole ? (
                <span
                    className="px-4 font-extrabold text-gray-500 hover:text-blue-900 flex items-center cursor-pointer"
                    onClick={() => {
                        handleLogout();
                        closeDropdown();
                    }}
                >
                    <FaUser className="inline mr-1" /> Logout
                </span>
            ) : (
                <HashLink
                    className="px-4 font-extrabold text-gray-500 hover:text-blue-900 flex items-center"
                    to="/login"
                    onClick={() => {
                        onClick();
                        closeDropdown();
                    }}
                >
                    <FaUser className="inline mr-1" /> Login
                </HashLink>
            )}
        </div>
    );
};

export default NavLinks;



// import React, { useState, useEffect } from 'react';
// import { HashLink } from 'react-router-hash-link';
// import { FaChevronDown } from 'react-icons/fa';

// import img from "../../images/web.svg";
// import img1 from "../../images/undraw_web_development_0l6v.svg";
// import img2 from "../../images/app.svg";
// import img3 from "../../images/hosting.svg";
// import img4 from "../../images/consultation.svg";
// import img5 from "../../images/undraw_content_team_re_6rlg.svg";
// import img6 from "../../images/undraw_security_re_a2rk.svg";
// import img7 from "../../images/seo.svg";
// import img8 from "../../images/undraw_mobile_marketing_re_p77p.svg";
// import img9 from "../../images/undraw_calculator_re_alsc.svg";
// import img10 from "../../../images/undraw_growth_curve_re_t5s7.svg";
// import img11 from "../../images/undraw_crypto_portfolio_2jy5.svg";
// import Modal from '../Modal';
// const servicesData = {
//     'custom-software-development': {
//         title: 'Custom Software Development',
//         description: 'At Skyland Web, we assist you in crafting diverse and sophisticated software solutions tailored to your business requirements. By attentively listening to your ideas, we meticulously implement and design custom software solutions from the ground up, uniquely suited to your business. With Skyland Web, you can trust that you\'ll receive top-notch software and impeccable service with every project.',
//         img: img
//     },
//     'web-application-development': {
//         title: 'Web Application Development',
//         description: 'At Skyland Web, we go beyond conventional solutions to create innovative web applications. Our skilled and dedicated web development team understands your needs and leverages modern web frameworks to develop high-value business applications. Whether you need a simple website or a complex web platform, we are here to turn your vision into reality.',
//         img: img1
//     },
//     'mobile-app-development': {
//         title: 'Mobile App Development',
//         description: 'We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.',
//         img: img2
//     },
//     'domain-hosting-services': {
//         title: 'Domain and Hosting Services',
//         description: 'We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.',
//         img: img3
//     },
//     'it-consultations': {
//         title: 'General IT Consultations',
//         description: 'Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.',
//         img: img4
//     },
//     'content-creation-services': {
//         title: 'Content Creation Services',
//         description: 'Custom logos, image generation, videos, banner designs, leaflets/flyers, business cards, and marketing materials to enhance your brand\'s visibility and appeal.',
//         img: img5
//     },
//     'cybersecurity-services': {
//         title: 'Cybersecurity Services',
//         description: 'Penetration testing to identify vulnerabilities and secure your systems from potential threats.',
//         img: img6
//     },
//     'seo-services': {
//         title: 'SEO Services',
//         description: 'Offline and online SEO strategies to boost your search engine rankings and drive organic traffic to your site.',
//         img: img7
//     },
//     'digital-marketing-services': {
//         title: 'Digital Marketing Services',
//         description: 'Google, Facebook, Instagram, TikTok, Twitter, YouTube, and influencer marketing to expand your online presence and engage with your target audience.',
//         img: img8
//     },
//     'accounting-services': {
//         title: 'Accounting Services',
//         description: 'Bookkeeping, virtual CFO services, and management of corporate finance to streamline your financial operations.',
//         img: img9
//     },
//     'data-entry-services': {
//         title: 'Data Entry Services',
//         description: 'Professional data entry solutions to ensure accuracy and efficiency in handling your data.',
//         img: img10
//     },
//     'crypto-tracing-services': {
//         title: 'Crypto Tracing Services',
//         description: 'Skyland Web provides thorough crypto tracing services, enabling you to monitor and analyze blockchain transactions with ease. Our expert team ensures transparency and security in your cryptocurrency operations, offering insights to support informed decision-making.',
//         img: img11
//     },
//     'outreach-services': {
//         title: 'Outreach Services',
//         description: 'Skyland Web\'s Outreach Services are dedicated to helping you extend your reach and impact within your community. We offer strategic planning, community engagement, and outreach program development to connect you with your target audience effectively. Our services include cold calling, cold emailing, and personalized outreach strategies to ensure your message resonates. Let us help you build strong, lasting relationships and foster meaningful connections through our expert outreach services.',
//         img: img10
//     }
// };


// const NavLinks = ({ onClick }) => {
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedService, setSelectedService] = useState(null);

//     const handleDropdownToggle = () => {
//         setIsDropdownOpen(prevState => !prevState);
//     };

//     const closeDropdown = () => {
//         setIsDropdownOpen(false);
//     };

//     const openModal = (service) => {
//         setSelectedService(servicesData[service]);
//         setIsModalOpen(true);
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setSelectedService(null);
//     };

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (!event.target.closest('.dropdown-wrapper')) {
//                 closeDropdown();
//             }
//         };

//         document.addEventListener('click', handleClickOutside);
//         return () => {
//             document.removeEventListener('click', handleClickOutside);
//         };
//     }, []);

//     return (
//         <div className="relative dropdown-wrapper">
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 smooth
//                 to="/#about"
//                 onClick={() => { onClick(); closeDropdown(); }}
//             >
//                 About
//             </HashLink>

//             <div className="relative inline-block">
//                 <span
//                     className="px-4 font-extrabold text-gray-500 hover:text-blue-900 cursor-pointer"
//                     onClick={handleDropdownToggle}
//                 >
//                     Services <FaChevronDown className="inline ml-1" />
//                 </span>
//                 {isDropdownOpen && (
//                     <div className="absolute left-0 mt-2 w-[300px] bg-white shadow-lg rounded-lg z-10">
//                         <div className="grid grid-cols-2 gap-2 p-4">
//                             {Object.keys(servicesData).map((service, index) => (
//                                 <span
//                                     key={index}
//                                     className="text-gray-700 hover:text-blue-900 cursor-pointer"
//                                     onClick={() => {
//                                         closeDropdown();
//                                         openModal(service);
//                                     }}
//                                 >
//                                     {service.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
//                                 </span>
//                             ))}
//                         </div>
//                     </div>
//                 )}
//             </div>

//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/contact#contact"
//                 onClick={() => { onClick(); closeDropdown(); }}
//             >
//                 Contact Us
//             </HashLink>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/consultation#consultation"
//                 onClick={() => { onClick(); closeDropdown(); }}
//             >
//                 Free Consultation
//             </HashLink>
//             <HashLink
//                 className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
//                 to="/skyland-va"
//                 onClick={() => { onClick(); closeDropdown(); }}
//             >
//                 SKYLAND VA
//             </HashLink>
//             <HashLink
//                 className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
//                 smooth
//                 to="/get-demo#demo"
//                 onClick={() => { onClick(); closeDropdown(); }}
//             >
//                 Demo our products
//             </HashLink>

//             {/* Modal Component */}
//             <Modal
//                 isOpen={isModalOpen}
//                 onClose={closeModal}
//                 content={selectedService || {}}
//             />
//         </div>
//     );
// };

// export default NavLinks;
