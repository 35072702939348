import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import axios from 'axios';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import Footer from '../components/Footer';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
        const response = await axios.post('https://skyland-backend.vercel.app/api/v1/auth/login', { email, password });
        
        // Handle successful response
        Notiflix.Report.success(
            'Success',
            'Login successfully!',
            'Okay',
        );
        console.log('Login successful:', response.data);

        // Store the user's role and token in localStorage
        const userRole = response.data.data.user.role;
        const token = response.data.data.token;
        localStorage.setItem('userRole', userRole);
        localStorage.setItem('token', token);

        // Redirect to the home route
        window.location.href = '/';
    } catch (err) {
        // Handle error response
        setError('Invalid email or password');
        Notiflix.Notify.failure('Login failed. Please check your credentials.');
        console.error('Login error:', err);
    } finally {
        setLoading(false);
    }
};


  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div id='login' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Login</h2>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>

            {/* Registration link */}
            <div className="mt-4 text-center">
              <p className="text-gray-700">
                New here?{' '}
                <Link to="/register" className="text-blue-500 hover:text-blue-700">
                  Register now
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
