import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import FreeConsultation from './pages/FreeConsultation';
import VirtualAssistant from './pages/VirtualAssistant';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import AllContacts from './pages/Dashboard/AllContacts/AllContacts';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Skyland Web");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/consultation" element={<FreeConsultation />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/skyland-va" element={<VirtualAssistant />} /> 
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register/>} />
            {/* <Route path="/dashboard" element={<Dashboard/>} /> */}
            {/* Protect the dashboard route */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/all-contacts" element={<AllContacts/>} />
          
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
